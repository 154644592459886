<template>
  <div class="py-16">
    <t as="p" path="trader_8" class="f-bold f-nm mb-4" style="line-height: 28px;">
      Positions
    </t>
    <AsyncNullableList
        :identify="v => v.code"
        :list-data="info"
      >
      <template #="{ item }">
        <div class="df-middle py-10 f-md">
          <p class="f-medium code">{{prop('code', item)}}</p>
          <p class="flex-1 c-success" :class="{'c-danger': !isBuy(item.type)}">
            <t :path="isBuy(item.type) ? 'symbol_9' : 'symbol_10'">
              {{isBuy(item.type) ? 'Buy' : 'Sell'}}
            </t>
            @{{prop('createPrice', item)}}
          </p>
          <Time class="c-text f-400" format="MM-dd-yy HH:mm:ss" :time="prop('createTime', item)" />
        </div>
      </template>
    </AsyncNullableList>
  </div>
  <Line />
</template>

<script lang="ts">
import { TradeDirection } from '@/types'
import { defineComponent } from 'vue'
import Line from '@/components/Line.vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import Time from '@/components/Time.vue'

export default defineComponent({
  name: 'ThePositions',
  components: { Line, AsyncNullableList, Time },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const isBuy = (type: number) => type === TradeDirection.BUY

    return {
      isBuy,
    }
  },
})
</script>

<style scoped lang="scss">
  .code {
    min-width: 80px;
    max-width: 80px;
  }
</style>
