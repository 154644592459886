<template>
  <div class="py-16">
    <t as="p" path="trader_5" class="f-bold f-nm" style="line-height: 28px;">
      Profile
    </t>
    <t as="p" path="" style="line-height: 24px; margin-top: 2px;" class="f-400">
      {{ info.profile }}
    </t>
  </div>
  <Line />
  <div class="py-16">
    <t as="p" path="trader_6" class="f-bold f-nm" style="line-height: 28px;">
      Strategy
    </t>
    <t as="p" path="" style="line-height: 24px; margin-top: 2px;" class="f-400">
      {{ info.strategy }}
    </t>
  </div>
  <Line />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Line from '@/components/Line.vue'

export default defineComponent({
  name: 'TheProfile',
  components: { Line },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
})
</script>
