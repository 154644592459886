<template>
  <PageWithHeader>
    <template #title><t path="trader_2">Detail</t></template>
    <div class="px-16" style="padding-bottom: 50px;">
      <Holder :content="traderInfo" :skeleton-repeat="2" holder-class="px-16">
        <TheDetailHeader :info="traderInfo" />
        <TheProfile :info="{profile: traderInfo.profile, strategy: traderInfo.strategy}" />
        <ThePerformance :info="traderInfo" />
        <ThePositions :info="traderInfo.holdOrderlist" />
        <TheHistory :info="traderInfo.historyOrderList" />
      </Holder>
    </div>
    <Button :progress="loading" :class="['btn f-bold primary copy', {primary: !isCopied, asst: isCopied}]" @click="handleCopyClick()">
      <t :path="isCopied?'copytrading_10':'copytrading_9'" #="{td}" custom>{{td || 'Copy'}}</t>
    </Button>
  </PageWithHeader>
</template>

<script lang="ts">
import { defineComponent, ref, shallowRef } from 'vue'
import Holder from '@/provider/Holder.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheDetailHeader from '@/modules/copyTrade/components/TheDetailHeader.vue'
import ThePerformance from '@/modules/copyTrade/components/ThePerformance.vue'
import TheProfile from '@/modules/copyTrade/components/TheProfile.vue'
import ThePositions from '@/modules/copyTrade/components/ThePositions.vue'
import TheHistory from '@/modules/copyTrade/components/TheHistory.vue'
import { useCopyTrade } from '@/modules/copyTrade/copyTrade'
import { readTraderInfo, ITraderItem } from '@/modules/copyTrade/copyTrade.api'
import { useRoute } from 'vue-router'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'Detail',
  components: { ThePositions, TheProfile, ThePerformance, TheDetailHeader, PageWithHeader, Holder, TheHistory, Button },
  props: {
    orderId: String,
  },
  setup () {
    const isCopied = ref(false)
    const router = useRoute()
    const traderInfo = shallowRef<ITraderItem>()
    const traderId = router.params.orderId
    const { loading, toggle } = useCopyTrade()

    const getTraderInfo = (id: number) => {
      readTraderInfo({ traderId: id }).then((res) => {
        traderInfo.value = res
        isCopied.value = Boolean(res.copyStatus)
      })
    }

    if (!traderId) {
      return false
    } else {
      getTraderInfo(+traderId)
    }

    const handleCopyClick = () => {
      if (traderInfo.value === undefined) {
        return false
      }
      toggle(traderInfo.value, () => {
        getTraderInfo(+traderId)
      })
    }

    return {
      loading,
      traderInfo,
      isCopied,
      handleCopyClick,
    }
  },
})
</script>

<style scoped lang="scss">
  .copy {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }
</style>
