<template>
  <div class="py-16">
    <t as="p" path="trader_11" class="f-bold f-nm mb-4" style="line-height: 28px;">
      History
    </t>
    <AsyncNullableList
      :identify="v => v.code"
      :list-data="info"
    >
      <template #="{ item }">
        <TheHistoryItem :order="item"  />
      </template>
    </AsyncNullableList>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import TheHistoryItem from '@/modules/copyTrade/components/TheHistoryItem.vue'

export default defineComponent({
  name: 'TheHistory',
  components: { TheHistoryItem, AsyncNullableList },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup () {
    return {

    }
  },
})
</script>
