<template>
  <div class="df-middle py-10 f-md" @click="toggleSlide">
    <Time class="close-time" format="MM-dd-yy" :time="prop('closeTime', model)" />
    <p class="flex-1">{{prop('code', model)}}</p>
    <p class="c-success" :class="{'c-danger': prop('profitability', model)<0}">{{ prop('profitability', model)>0?'+'+prop('profitability', model):prop('profitability', model) }}%</p>
    <Icon :name="show ? 'caret-up' : 'caret-down'" sm class="ml-12" />
  </div>
  <div class="df-between f-sm c-title bg-background px-16 py-8" style="border-radius: 2px;"
       v-if="show"
  >
    <div class="flex-1">
      <p class="c-text mb-8">
        <t path="sharelink_6">Close</t>
        @{{prop('closePrice', model)}}
      </p>
      <p class="c-success" :class="{'c-danger': !isBuy}">
        <t :path="isBuy ? 'symbol_9' : 'symbol_10'">
          {{isBuy ? 'Buy' : 'Sell'}}
        </t>
        @{{prop('createPrice', model)}}
      </p>
    </div>
    <div>
      <Time tag="p" class="mb-8" format="MM-dd-yy HH:mm:ss" :time="prop('closeTime', model)" />
      <Time tag="p" format="MM-dd-yy HH:mm:ss" :time="prop('createTime', model)" />
    </div>
  </div>
</template>

<script lang="ts">
import { TradeDirection } from '@/types'
import { computed, defineComponent, ref, shallowRef } from 'vue'
import Time from '@/components/Time.vue'
import Icon from '@/components/Icon.vue'
import { mul } from 'essential/tools/math'

export default defineComponent({
  name: 'TheHistoryItem',
  components: { Icon, Time },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const model = shallowRef(props.order)
    model.value.profitability = mul(props.order.profitability, 100)

    const isBuy = computed(() => props.order.type === TradeDirection.BUY)

    const show = ref(false)

    return {
      model,
      isBuy,
      show,
      toggleSlide: () => show.value = !(show.value),
    }
  },
})
</script>

<style scoped lang="scss">
  .close-time {
    min-width: 120px;
    max-width: 120px;
  }
</style>
