<template>
  <div class="py-16">
    <t as="p" path="trader_7" class="f-bold f-nm" style="line-height: 28px;">
      Performance
    </t>
    <p class="df-middle c-title f-md mt-8">
      <ColorText
        class="f-nm c-success mr-8 f-medium"
        :value="model.totalRatio">
        {{model.totalRatio + '%'}}
      </ColorText>
      <t path="copytrading_5">Last 7 days</t>
    </p>
    <div>
      <div class="lite-chart" ref="chartRef" />
    </div>
  </div>
  <Line />
</template>

<script lang="ts">
import * as R from 'ramda'
import { defineComponent, onMounted, reactive, ref } from 'vue'
import Line from '@/components/Line.vue'
import ColorText from '@/components/ColorText.vue'
import createChart from '@/components/chart/chart'
import { mul } from 'essential/tools/math'
interface ChartData {
  issue: string;
  rateReturn: number;
}
export default defineComponent({
  name: 'ThePerformance',
  components: { Line, ColorText },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const chartRef = ref()
    const model = reactive(props.info)
    model.ratio = R.isEmpty(model.ratio) ? [] : model.ratio
    model.totalRatio = mul(model.totalRatio, 100)
    onMounted(() => {
      const draw = createChart({
        container: chartRef.value,
        color: [50, 107, 254],
      })
      draw.setData(model.ratio.map((item: ChartData) => {
        return {
          c: item.rateReturn,
        }
      }))
    })
    return {
      model,
      chartRef,
    }
  },
})
</script>

<style>
.lite-chart{
  height: 80px;
  width: 100%;
}
</style>
