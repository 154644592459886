<template>
  <div class="d-f py-16 p-r">
    <Pic
      :src="dInfo.avatar" fallback="/img/user/Avatar@2x.png"
      width="64" height="64" style="border-radius: 50%;"
    />
    <div class="ml-16 flex-1">
      <p class="f-bold" style="line-height: 24px;">{{ dInfo.nikeName }}</p>
      <div class="df-middle mt-8 mb-4 f-md c-title">
        <p class="c-success f-xl f-medium mr-4" style="line-height: 24px;">{{ dInfo.copyers }}</p>
        <t path="trader_3" class="mr-16">Copiers</t>
        <p class="c-success mr-4 f-xl f-medium" style="line-height: 24px;">{{ dInfo.likes }}</p>
        <t path="trader_4">Likes</t>
      </div>
      <TheLabel :labels="dInfo.labels" />
    </div>
    <div class="like df-cross-center" :class="{liked: isLike}" @click="toggleLike">
      <Icon name="thumb-up" sm />
    </div>
  </div>
  <Line />
</template>

<script lang="ts">
import { defineComponent, ref, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import Line from '@/components/Line.vue'
import Icon from '@/components/Icon.vue'
import TheLabel from '@/modules/copyTrade/components/TheLabel.vue'
import { apiTraderLike } from '@/modules/copyTrade/copyTrade.api'

export default defineComponent({
  name: 'TheDetailHeader',
  components: { Pic, Line, Icon, TheLabel },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const isLike = ref(false)
    const dInfo = shallowRef(props.info)
    isLike.value = Boolean(+props.info.isLike === 1)

    const toggleLike = () => {
      apiTraderLike({ traderId: props.info.traderId, like: Number(!(isLike.value)) }).then(() => {
        if (!(isLike.value)) {
          dInfo.value.likes = dInfo.value.likes * 1 + 1
        } else {
          dInfo.value.likes = dInfo.value.likes * 1 - 1
        }
        isLike.value = !(isLike.value)
      })
    }
    return {
      isLike,
      dInfo,
      toggleLike,
    }
  },
})
</script>

<style scoped lang="scss">
.like {
  position: absolute;
  right: 0;
  top: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--color-light);
  color: var(--color-title);
}
.liked {
  color: var(--color-primary);
}
</style>
